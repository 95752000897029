import * as React from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table
} from "reactstrap";
import {
  clearEditRolePredicates,
  handleDeleteRolePredicate,
  handleLoadEditRolePredicates,
  handleSaveRolePredicate,
  handleUpdateRolePredicate
} from "../../../actions/editRolePredicates";
import DataLoading from "../../../components/organisms/DataLoading";
import IPredicate from "../../../models/IPredicate";
import IRolePredicate from "../../../models/IRolePredicate";
import { IStore } from "../../../models/IStore";

export interface IAssignRolePredicatesState {
  activePredicateId: number;
  activePredicateValue: string;
  editRolePredicates: IRolePredicate[];
}

export interface IAssignRolePredicatesProps {
  accessToken: string;
  editRolePredicates: IRolePredicate[];
  editRolePredicatesLoaded: boolean;
  predicates: IPredicate[];
  predicatesLoaded: boolean;
  roleId: number;
  onHandleSaveRolePredicate: (
    accessToken: string,
    rolePredicate: IRolePredicate
  ) => void;
  onHandleUpdateRolePredicate: (
    accessToken: string,
    rolePredicate: IRolePredicate
  ) => void;
  onHandleLoadRolePredicates: (accessToken: string, roleId: number) => void;
  onHandleRemoveRolePredicate: (
    accessToken: string,
    roleId: number,
    predicateId: number
  ) => void;
  onClearRolePredicates: () => void;
}

export class AssignRolePredicates extends React.Component<
  IAssignRolePredicatesProps,
  IAssignRolePredicatesState
> {
  public state = {
    activePredicateId: 1,
    activePredicateValue: "",
    editRolePredicates: [] as IRolePredicate[]
  };
  constructor(props: IAssignRolePredicatesProps) {
    super(props);
    this.predicateIdChange = this.predicateIdChange.bind(this);
    this.predicateValueChange = this.predicateValueChange.bind(this);
    this.removeRolePredicate = this.removeRolePredicate.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.readyToAdd = this.readyToAdd.bind(this);
  }

  public predicateIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const activePredicateId =
      e.target.value === undefined ? 1 : Number(e.target.value as string);
    this.setState(() => ({ activePredicateId }));
  };

  public predicateValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const activePredicateValue =
      e.target.value === undefined ? "" : e.target.value;
    this.setState(() => ({ activePredicateValue }));
  };

  public removeRolePredicate = (predicateId: number) => {
    const { accessToken, roleId, onHandleRemoveRolePredicate } = this.props;
    onHandleRemoveRolePredicate(accessToken, roleId, predicateId);
  };

  public readyToAdd = () => {
    const { activePredicateValue } = this.state;
    if (activePredicateValue === undefined || activePredicateValue === "") {
      return false;
    }
    return true;
  };

  public onAddClick = async (e: React.MouseEvent) => {
    const {
      activePredicateId,
      activePredicateValue,
      editRolePredicates
    } = this.state;
    const {
      accessToken,
      roleId,
      onHandleSaveRolePredicate,
      onHandleUpdateRolePredicate
    } = this.props;
    e.preventDefault();
    if (this.readyToAdd()) {
      const rolePredicate = editRolePredicates.find(
        rp => rp.predicateId === activePredicateId
      );
      if (rolePredicate === undefined) {
        onHandleSaveRolePredicate(accessToken, {
          roleId,
          // tslint:disable-next-line:object-literal-sort-keys
          predicateId: activePredicateId,
          value: activePredicateValue
        } as IRolePredicate);
      } else {
        const updatedValue = rolePredicate.value.concat(
          `, ${activePredicateValue}`
        );
        onHandleUpdateRolePredicate(accessToken, {
          roleId,
          // tslint:disable-next-line:object-literal-sort-keys
          predicateId: activePredicateId,
          value: updatedValue,
          isEnabled: rolePredicate.isEnabled
        } as IRolePredicate);
      }
      await this.setState(() => ({
        activePredicateId: 1,
        activePredicateValue: ""
      }));
    }
  };

  public async componentDidMount() {
    const {
      accessToken,
      roleId,
      onHandleLoadRolePredicates,
      onClearRolePredicates,
      editRolePredicates
    } = this.props;
    await onClearRolePredicates();
    await onHandleLoadRolePredicates(accessToken, roleId);
    await this.setState(() => ({ editRolePredicates }));
  }

  public async componentDidUpdate(prevProps: IAssignRolePredicatesProps) {
    const { editRolePredicates } = this.props;
    if (prevProps.editRolePredicates !== editRolePredicates) {
      await this.setState(() => ({ editRolePredicates }));
    }
  }

  public render() {
    const {
      predicates,
      predicatesLoaded,
      editRolePredicatesLoaded
    } = this.props;
    const {
      editRolePredicates,
      activePredicateId,
      activePredicateValue
    } = this.state;
    return (
      <React.Fragment>
        <h2>Add Predicate Values</h2>
        {!predicatesLoaded && <DataLoading dataTitle="Predicates " />}
        {!editRolePredicatesLoaded && (
          <DataLoading dataTitle="Role Predicates " />
        )}
        {predicatesLoaded && editRolePredicatesLoaded && (
          <Table striped={true}>
            <thead>
              <tr>
                <th>Predicate Name</th>
                <th>Predicate Value</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {editRolePredicates.map(sp => (
                <React.Fragment key={sp.predicateId}>
                  <tr>
                    <td>
                      {
                        (predicates.find(
                          pred => pred.id === sp.predicateId
                        ) as IPredicate).displayName
                      }
                    </td>
                    <td>
                      {sp.value.length > 25 ? (
                        <textarea
                          disabled={true}
                          style={{ width: "400px", height: "200px" }}
                          value={sp.value}
                        />
                      ) : (
                        sp.value
                      )}
                    </td>
                    <td>
                      <Button
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={(e: any) => {
                          this.removeRolePredicate(sp.predicateId);
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
        <Form>
          <FormGroup>
            <Container fluid={true}>
              <Row>
                <Col>
                  <Input
                    type="select"
                    placeholder="Predicate"
                    onChange={this.predicateIdChange}
                    value={activePredicateId.toString()}
                  >
                    {predicates.map(pred => (
                      <option key={pred.id} value={pred.id}>
                        {pred.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col>
                  <Input
                    type="text"
                    placeholder="Predicate Value"
                    onChange={this.predicateValueChange}
                    value={activePredicateValue}
                  />
                </Col>
                <Col>
                  <Button onClick={this.onAddClick}>Add</Button>
                </Col>
              </Row>
            </Container>
          </FormGroup>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    accessToken: state.auth0.accessToken,
    editRolePredicates: state.editRolePredicates,
    editRolePredicatesLoaded: state.loadingEditRolePredicates,
    predicates: state.predicates,
    predicatesLoaded: state.loadingPredicates,
    roleId: state.editRoleDetails.id
    // tslint:disable-next-line:object-literal-sort-keys
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onHandleLoadRolePredicates: (accessToken: string, roleId: number) => {
    dispatch(handleLoadEditRolePredicates(accessToken, roleId));
  },
  onHandleSaveRolePredicate: (
    accessToken: string,
    rolePredicate: IRolePredicate
  ) => {
    dispatch(handleSaveRolePredicate(accessToken, rolePredicate));
  },
  onHandleUpdateRolePredicate: (
    accessToken: string,
    rolePredicate: IRolePredicate
  ) => {
    dispatch(handleUpdateRolePredicate(accessToken, rolePredicate));
  },
  // tslint:disable-next-line:object-literal-sort-keys
  onClearRolePredicates: () => {
    dispatch(clearEditRolePredicates());
  },
  onHandleRemoveRolePredicate: (
    accessToken: string,
    roleId: number,
    predicateId: number
  ) => {
    dispatch(handleDeleteRolePredicate(accessToken, roleId, predicateId));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignRolePredicates);
